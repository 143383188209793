import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';


// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
// About
const About = React.lazy(() => import("./components/pages/About"));
// Services
const Services = React.lazy(() => import("./components/pages/Services"));
const Servicedetails = React.lazy(() => import("./components/pages/Servicedetails"));
const Projectmanagement = React.lazy(() => import("./components/pages/Projectmanagement"));
const Accounting = React.lazy(() => import("./components/pages/Accounting"));
const Timeregistration = React.lazy(() => import("./components/pages/Timeregistration"));
const Customermanagement = React.lazy(() => import("./components/pages/Customermanagement"));
const Stockmanagement = React.lazy(() => import("./components/pages/Stockmanagement"));
const Hr = React.lazy(() => import("./components/pages/Hr"));
const IpTelephony = React.lazy(() => import("./components/pages/IpTelephony"));
const Microsoft365 = React.lazy(() => import("./components/pages/Microsoft365"));
const Hitrate = React.lazy(() => import("./components/pages/Hitrate"));

// Industries
const Industries = React.lazy(() => import("./components/pages/Industries"));
// References
const References = React.lazy(() => import("./components/pages/References"));

//Prices
const Prices = React.lazy(() => import("./components/pages/Bloggrid"));

const Whyus = React.lazy(() => import("./components/pages/Whyus"));
const Team = React.lazy(() => import("./components/pages/Team"));
const Teamdetails = React.lazy(() => import("./components/pages/Teamdetails"));
const Portfoliogrid = React.lazy(() => import("./components/pages/Portfoliogrid"));
const Portfolioslider = React.lazy(() => import("./components/pages/Portfolioslider"));
const Portfoliomasonry = React.lazy(() => import("./components/pages/Portfoliomasonry"));
const Portfoliodetails = React.lazy(() => import("./components/pages/Portfoliodetails"));
const Faqs = React.lazy(() => import("./components/pages/Faqs"));

const Blogstandard = React.lazy(() => import("./components/pages/Blogstandard"));
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// Contact
const Contact = React.lazy(() => import("./components/pages/Contact"));


// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Preloader />
          {/* Home */}
          <Route exact path="/" component={Home} />
          <Route exact path="/home-v2" component={Hometwo} />
          <Route exact path="/home-v3" component={Homethree} />
          {/* About */}
          <Route exact path="/about" component={About} />
          {/* Services */}
          <Route exact path="/services" component={Services} />
          <Route exact path="/projectmanagement" component={Projectmanagement} />
          <Route exact path="/accounting" component={Accounting} />
          <Route exact path="/timeregistration" component={Timeregistration} />
          <Route exact path="/customermanagement" component={Customermanagement} />
          <Route exact path="/stockmanagement" component={Stockmanagement} />
          <Route exact path="/hr" component={Hr} />
          <Route exact path="/iptelephony" component={IpTelephony} />
          <Route exact path="/microsoft365" component={Microsoft365} />
          <Route exact path="/hitrate" component={Hitrate} />
          {/* Industries */}
          <Route exact path="/industries" component={Industries} />
          {/* References */}
          <Route exact path="/references" component={References} />
          {/* Prices */}
          <Route exact path="/prices" component={Bloggrid} />

          {/* Pages */}
          <Route exact path="/whyus" component={Whyus} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/team-details/:id" component={props => (<Teamdetails {...props} key={window.location.pathname} />)} />
          <Route exact path="/portfolio-grid" component={Portfoliogrid} />
          <Route exact path="/portfolio-slider" component={Portfolioslider} />
          <Route exact path="/portfolio-masonry" component={Portfoliomasonry} />
          <Route exact path="/portfolio-details/:id" component={props => (<Portfoliodetails {...props} key={window.location.pathname} />)} />
          <Route exact path="/faqs" component={Faqs} />
          {/* News */}
          <Route exact path="/blog/search/:query" component={props => (<Blogstandard {...props} key={window.location.pathname} />)} />
          <Route exact path="/blog/cat/:catId" component={props => (<Blogstandard {...props} key={window.location.pathname} />)} />
          <Route exact path="/blog/user/:userId" component={props => (<Blogstandard {...props} key={window.location.pathname} />)} />
          <Route exact path="/blog/tag/:tagId" component={props => (<Blogstandard {...props} key={window.location.pathname} />)} />

          <Route exact path="/blog-standard" component={Blogstandard} />
          <Route exact path="/blog-grid" component={Bloggrid} />
          <Route exact path="/blog-details/:id" component={props => (<Blogdetails {...props} key={window.location.pathname} />)} />
          {/* Contact */}
          <Route exact path="/contact" component={Contact} />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
